import { useId } from 'react';

import dynamic from 'next/dynamic';

import { PageTeaserFragment } from '@hultafors/eripiowear/types';

import { TeaserGrid } from '../teaser-grid/teaser-grid';

const TeaserSimple = dynamic(() =>
  import('../teaser-simple/teaser-simple').then((mod) => mod.TeaserSimple),
);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
// export interface ProductPlugsBlockProps
//   extends Omit<ProductPlugsBlockFragment, '__typename' | 'id'> {}

interface TeaserBlockProps {
  items: PageTeaserFragment[];
}
export const TeaserBlock: React.FC<TeaserBlockProps> = ({ items }) => {
  const uid = useId();
  const plugsMapper = (teaser: PageTeaserFragment) => {
    const key = `TeaserBlock-${uid}-${teaser.id}`;
    const href = teaser.slug || '';
    return (
      <TeaserSimple
        key={key}
        title={teaser.shortTitle || teaser.title || ''}
        image={teaser.teaserImage || teaser.image}
        url={href}
        color=""
      />
    );
  };
  return <TeaserGrid>{items?.map(plugsMapper)}</TeaserGrid>;
};
